@import "globals/media-query.scss";
@import "globals/colors.scss";

.product-secondary-navbar {
  background: rgba(250, 250, 250, 0.75);
  padding: 12px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: $white;
  z-index: 100;
  position: static !important;
  margin: 0 !important;
  min-height: 64px;
  border-bottom: 1px solid #EBEBEB;
  &.menu-active{
    background: #FFF;
    border: none;
  }
  h2 {
    color: $Jaguar;
    font: normal normal normal 16px/24px Sofia Pro;
    letter-spacing: 0px;
    // color: $white;
    margin: 0;
  }
  h4 {
    color: $Jaguar;
    font: normal normal normal 16px/24px Sofia Pro;
    letter-spacing: 0px;
    // color: $white;
    margin: 0;
    margin: 0;

    @media only screen and (max-width: 1499.9px) {
      // margin: 0 80px 0 auto;
    }

    @media only screen and (max-width: 1199.9px) {
      // margin: 0 70px 0 auto;
    }

    @media only screen and (max-width: 991.9px) {
      // margin: 0 60px 0 auto;
    }

    @media only screen and (max-width: 767.9px) {
      // margin: 0 50px 0 auto;
      font-size: 20px;
    }

    @media only screen and (max-width: 575.9px) {
      margin: 0 12px 0 0;
      padding-top: 2px;
      line-height: 24px;
      small {
        display: block;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }

    span {
      font-weight: 700;
    }
  }
  .dropper {
    display: none;
    cursor: pointer;
    img {
      transition: all 0.3s;
    }
  }
  .sec-nav-menu {
    list-style-type: none;
    display: inline-block;
    margin: 0;
    padding: 0 20px;
    li {
      display: inline-block;
      a {
        font: normal normal normal 16px/24px Sofia Pro;
        letter-spacing: 0px;
        color: $Jaguar;
        display: inline-block;
        padding: 10px 20px;
        cursor: pointer;

        @media only screen and (max-width: 1534.9px) {
          padding: 10px 0px;
        }

        @media only screen and (max-width: 1199.9px) {
          padding: 10px 15px;
        }

        &:hover {
          text-decoration: none;
          color: $Jaguar;
        }
      }
    }
  }
  .sec-nav-menu-new{
    list-style-type: none;
    display: inline-block;
    margin: 0;
    padding: 0 20px;
    @media only screen and (max-width: 1055.9px) {
      padding: 0;
    }
    li {
      display: inline-block;
      a {
        font: normal normal normal 16px/24px Sofia Pro;
        letter-spacing: 0px;
        color: $Jaguar;
        display: inline-block;
        padding: 10px 20px;
        cursor: pointer;

        @media only screen and (max-width: 1533.9px) {
          padding: 10px 0px;
          
        }

        @media only screen and (max-width: 1055.9px) {
          padding: 10px 0px;
          :last-of-type{
            margin-right: 16px;
          }
        }

        &:hover {
          text-decoration: none;
          color: $Jaguar;
        }
      }
    }
    @media only screen and (max-width: 360.9px) {
      white-space: nowrap;

      &.mobile-list {
        white-space: normal;
      }

      a span:last-of-type {
        margin-right: 12px;
      }
    }
  }

  .container {
    z-index: 0;
  }
}

.detail-page-header .product-secondary-navbar {
  &.whitening-subbar{
    .container{
      @media only screen and (min-width: 1500px) {
        max-width: 1150px;
      }
    }
  }
  .container {
    @media only screen and (min-width: 1500px) {
      max-width: 1150px;
    }
  }
}

.covid19-popup {
  display: flex;
  margin: 0 auto;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  .modal-content {
    border-radius: 15px;
    .modal-body {
      border-radius: 15px;
    }
  }
}

.modal-close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}
.modal-data-padding {
  padding: 64px 16px 56px 16px;
  text-align: center;
  h3 {
    font: normal normal normal 28px/36px Sofia Pro;
    letter-spacing: 0px;
    color: $Jaguar;
  }
}
.modal-open-btn {
  text-decoration: underline !important;
  cursor: pointer;
}
.cart-popup-overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 152px;
  background: rgba(40, 40, 41, 0.8);
  transition: top 0.3s ease;

  .cart-pop-container {
    position: absolute;
    top: 0;
    right: calc((100% - 1584px + 30px) / 2);

    @media only screen and (max-width: 1699.9px) {
      right: calc((100% - 1360px + 30px) / 2);
    }

    @media only screen and (max-width: 1499.9px) {
      right: calc((100% - 1140px + 30px) / 2);
    }

    @media only screen and (max-width: 1199.9px) {
      right: calc((100% - 960px + 30px) / 2);
    }

    @media only screen and (max-width: 1023.9px) {
      right: 0;
    }

    &.has-notifybar {
      top: 0;
    }
  }
}

.disable-notify-bar .cart-popup-overlay {
  top: 112px;
}

.scroll-down .sticky.detail-page-header .cart-popup-overlay {
  top: 64px;

  @media only screen and (max-width: 1055.9px) {
    top: 48px;
  }
}

.scroll-up .sticky.detail-page-header .cart-popup-overlay {
  top: 112px;

  @media only screen and (max-width: 1055.9px) {
    top: 48px;
  }
}

.secondary-right {
  a.web-btn {
    padding: 8px 24px;
    min-width: 0;
    line-height: 1.715;
    border: none;
    font-size: 14px;
    transition: none;

    &:hover {
      background: #2388B5;
    }
  }

  a.uvbox-morebtn {
    min-width: 190px !important;
  }

}
.link-button{
  all: unset;
  display: flex;
  padding: 4px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 48px;
  border: 1px solid #008CFF;
  color: #008CFF;
  text-align: center;
  font-family: Sofia Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
  min-width: fit-content;
  
  &:hover {
    color: #008CFF;
    text-decoration: none;
  }

}

@include mobile {
  .product-secondary-navbar {
    padding: 8px 0;
    position: absolute;
    height: 64px;
    h2 {
      font: normal normal normal 16px/24px Sofia Pro;
      letter-spacing: 0px;
      color: $Jaguar;
      margin: 0;
      width: auto;
      max-width: calc(100% - 200px);

      @media only screen and (max-width: 991.9px) {
        max-width: calc(100% - 180px);
        // font-size: 20px;
      }
    }
    .secondary-right {
      position: static;
    }
    .dropper {
      display: block;
      margin-right: auto;
      margin-left: 20px;

      @media only screen and (max-width: 991.9px) {
        margin-left: 15px;
      }
    }
    .sec-nav-menu {
      max-height: 0;
      position: absolute;
      width: 100%;
      background: #04296a;
      left: 0;
      right: 0;
      top: 160px;
      padding: 0 50px;
      transition: all 0.3s ease;
      overflow: hidden;
      z-index: 1;

      li {
        display: block;
        padding: 0;

        &:first-of-type {
          margin-top: 20px;
        }

        &:last-of-type {
          margin-bottom: 20px;
        }

        a {
          font: normal normal normal 16px/1.3 Sofia Pro;
          display: inline-block;
          padding: 15px;
          padding-left: 0;
        }
        &:not(:last-child) {
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        }
      }
    }
    .web-btn {
      min-width: 100px !important;
      line-height: 1.6;
    }
    &.active {
      .dropper {
        img {
          transform: rotate(180deg);
        }
      }
      .sec-nav-menu {
        max-height: 400px;
      }
    }
  }
  .cart-popup-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 110px;
    background: rgba(40, 40, 41, 0.95);
    transition: all 0.3s ease;

    .cart-pop-container {
      position: absolute;
      top: 70px;
      right: 0;
      width: 100%;

      &.has-notifybar {
        top: 0;
      }
    }
  }
}

@media only screen and (max-width: 575.9px) {
  .product-secondary-navbar .dropper {
    margin-right: auto;
    margin-left: 10px;
    transform: scale(1.3);
  }

  .product-secondary-navbar h2 {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 500;
    max-width: 130px;
  }
  .modal-data-padding {
    padding: 30px 16px 25px 16px;
  }

  header.sticky .cart-popup-overlay {
    top: 0;
  }
}

.product-subbar-sm {
  padding: 0;
  min-height: 48px;

  @media only screen and (max-width: 1023.9px) {
    height: 48px;
  }

  .web-btn {
    padding: 4px 24px;
    min-width: 160px;
    font-size: 14px;
    line-height: 1.715;
    border: none;
    transition: none;
    
    &:hover {
      background: #2388B5;
    }

    @media only screen and (max-width: 1023.9px) {
      min-width: 163px !important;
    }
  }

  a span {
    color: #6E6E73;
    font-size: 14px;
    line-height: 1.715;

    @media only screen and (max-width: 767.9px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .slink-redirection {
    cursor: pointer;
  }

  .subbar-mobile-menu{
    display: flex;
    justify-content: center;
    align-items: center;
    color:#6E6E73;
    text-align: center;
    font-family: Sofia Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
    position: relative;
    a{
      padding: 0 !important;
      :last-of-type{
      margin-right: 0 !important;
      }
    }
    span{
      img{
        margin-left: 8px;
        transform: rotate(0deg);
        transition: transform .5s ease-in-out;
      }   
      .menu-open{
        transform: rotate(180deg);
        
      }   
    }
    
    .subbar-dropdown-menu{
      background: #FFF;
      // box-shadow: 4px 8px 16px 0px rgba(142, 153, 168, 0.15);
      position: absolute;
      top: 42px;
      padding: 8px 0;
      z-index: 1;
      ul{
        padding-left: 0;

      }
      
      li{
        text-align: left;
        width: 327px;
        padding: 12px 16px 12px 0;
        margin-left: -16px;
        a{
          color:  #282829;
          font-family: Sofia Pro;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; 
        }
      }
      
      .selected-option{
        a{
          color: #008CFF;
          font-family: Sofia Pro;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
      }

    }
  }
  
}
.jp-header{
  .logo-wrapper{
    border-bottom: 1px solid #E6E6E6;
    background: rgba(250, 250, 250, 0.75);
  }
}

.jp-navbar{
  width: 100%;
  padding: 0 56px;

  .product-secondary-navbar{
    border-bottom: 1px solid #E6E6E6;
    background: rgba(250, 250, 250, 0.75);
  }

  @media only screen and (max-width: 1218.9px) {
    padding: 8px 16px;
  }
  
  @media only screen and (max-width: 991.9px) {
    padding: 8px 16px;
  }


  .container{
    max-width: 100%;
    padding: 0 0;
  }

  .sec-nav-menu-new{
    padding: 0 0;
  }


  .desktop-list{
    display: block;

    span{
      margin: 0 16px !important;
      cursor: pointer;

      &:first-child{
        margin: 0;
      }
    }
    a{
      li{
        margin: 0 16px;
        
      }
    }

    @media only screen and (max-width: 1135.9px) {
      display: none;
    }
    
  }

  .mobile-list-modal-wrapper {
    display: none;
    position: fixed;
    top: 145px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 145px);
    overflow-y: hidden;
    background-color: rgba(0, 0, 0, 0.25);;
    &.active {
      display: block;
    }
  }
  
  .mobile-list{
    display: none;

    .subbar-dropdown-menu {
      width: 100vw;
      margin-left: -16px;
      left: 0;
      top: 36px;

      @media only screen and (max-width: 1024.9px) {
        margin-left: -56px;
      }
      @media only screen and (max-width: 991.9px) {
        margin-left: -16px;
      }
      
      @media only screen and (max-height: 527.9px) {
        height: 300px;
        overflow: scroll;
      }
      
      // @media only screen and (max-width: 350px) {
      //   width: calc(100vw - 64px);
      // }

      ul{
        display: flex;
        flex-direction: column;
        text-align: left;

        @media only screen and (max-width: 1135.9px) {
          padding-left: 38px;
        }

        @media only screen and (max-width: 1024.9px) {
          padding-left: 72px;
        }
        @media only screen and (max-width: 991.9px) {
          padding-left: 32px;
        }
      }
    }

    @media only screen and (max-width: 1135.9px) {
      display: block;
    }

    li{
      cursor: pointer;
    }
  }
}