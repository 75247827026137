@import "globals/globals.scss";
@import "globals/media-query.scss";
.chat-bot-button{
    position: fixed;
    right: 24px;
    bottom: 24px;
    z-index: 101;
    transition: all .5s;
    cursor: pointer;

    @media only screen and (max-width: 1024.9px) {
        right: 16px;
        bottom: 16px;
    }
    // svg{
    //     width: 100%;
    // }
    span.chatbot-close {
        display: block;
        background-color: $btnColor;
        border-radius: 50%;
        width: 64px;
        height: 64px;
        text-align: center;
        padding: 16px 0;

        @media only screen and (max-width: 1024.9px) {
            width: 40px;
            height: 40px;
        }

        img{
            width: 32px;
            padding: 4px;

            @media only screen and (max-width: 1024.9px) {
                width: 24px;
            }
        }

    }
    .mobile-chatbot{
        display: none;
    }
}
@media only screen and (max-width: 767.9px) {
    .chat-bot-button{
        // width: 50px;
        .web-chatbot{
            display: none;
        }
        .mobile-chatbot{
            // width: 100%;
            display: block;
        }
    }
}

// .chat-bot-button {
//     transform: rotate(-90deg) translate(50%, -50%);
//     transform-origin: right;
// }

.chat-with-us {
    display: flex;
    align-items: center;
    padding: 16px;
    background: $btnColor;
    border-radius: 50%;

    @media only screen and (max-width: 1024.9px) {
        padding: 8px;
    }

    span {
        width: 32px;
        height: 32px;
        display: block;

        @media only screen and (max-width: 1024.9px) {
            width: 24px;
            height: 24px;
        }

        img {
            width: 100%;
            display: block;
        }
    }

    p {
        font-size: 24px;
        line-height: 1.55;
        color: $white;
        margin: 0;
        margin-right: 16px;
        padding-top: 2px;

        @media only screen and (max-width: 1499.9px) {
            font-size: 22px;
        }

        @media only screen and (max-width: 1199.9px) {
            font-size: 20px;
        }

        @media only screen and (max-width: 767.9px) {
            font-size: 14px;
            margin-right: 12px;
            padding-top: 0;
        }
    }
}