@import "media-query.scss";
@import "../variables.scss";

html,
body {
  padding: 0;
  margin: 0;
  font-family: Sofia Pro, sans-serif;
}

.wrapper {
  min-height: 200px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#ymDivCircle {
  display: none !important;
}

#ymFrameHolder {
  bottom: 0 !important;
  right: 90px !important;
}

.web-btn {
  min-width: 238px;
  border-radius: 25px;
  font-family: "Sofia Pro";
  font-size: 16px;
  line-height: 1.4;
  border: 2px solid $btnColor;
  display: inline-block;
  padding: 12px 12px 10px;
  text-decoration: none;
  transition: all 0.2s ease;
  box-shadow: none !important;
  cursor: pointer;
  color: $btnColor;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: inherit;
    outline: none;
    color: $btnColor;
  }

  &--fill {
    background-color: $btnColor;
    color: $white !important;

    &:hover,
    &:focus {
      background-color: $btnColor;
      color: $white !important;
    }
  }

  &.disabled {
    pointer-events: none;
    background-color: #ebebeb;
    color: #999999 !important;
    border-color: #ebebeb;
    opacity: 1 !important;

    span {
      color: #999999 !important;
    }
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1360px;
  }
}

@media (min-width: 1700px) {
  .container {
    max-width: 1584px;
  }
}

@include mobile {
  .web-btn {
    min-width: 188px;
    font-size: 14px;
    line-height: 1.6;
  }

  .container,
  .container-fluid {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.instafeed-section {
  width: 1210px;
  margin: 0 auto;
  max-width: 100%;
  padding: 100px 0;
  background: white;
  position: relative;

  &.width-full {
    width: 100%;
  }

  @media only screen and (max-width: 1499.9px) {
    padding: 80px 0px;
  }

  @media only screen and (max-width: 1199.9px) {
    padding: 70px 0px;
  }

  @media only screen and (max-width: 991.9px) {
    padding: 60px 0px;
  }

  @media only screen and (max-width: 575.9px) {
    padding: 40px 0px;
  }

  h2 {
    text-align: left;
    font-weight: 300;
    font-size: 60px;
    line-height: 1.17;
    font-family: 'Sofia Pro';
    letter-spacing: 0px;
    color: $textColor;
    margin: 0 auto 60px;

    @media only screen and (max-width: 1499.9px) {
      font-size: 52px;
      margin: 0 auto 50px;
    }

    @media only screen and (max-width: 1199.9px) {
      font-size: 45px;
      margin: 0 auto 40px;
    }

    @media only screen and (max-width: 991.9px) {
      font-size: 38px;
      margin: 0 auto 30px;
    }

    @media only screen and (max-width: 575.9px) {
      font-size: 32px;
      font-weight: normal;
      text-align: left;
      margin-left: 10px;
    }
  }

  .row {
    margin: -7.5px;

    @media only screen and (max-width: 575.9px) {
      margin: -5px;
    }
  }

  &.singleline {
    @media only screen and (max-width: 575.9px) {
      overflow: hidden;

      .row {
        margin: -5px -33px;
      }

      h2 {
        margin-left: 0;
      }
    }
  }

  [class*='col-'] {
    padding: 7.5px;

    @media only screen and (max-width: 575.9px) {
      padding: 5px;
    }
  }

  .img-overlay {
    transition: all 0.3s ease;
    z-index: 2;
  }

  .ig-img-wrapper .type-icon {
    z-index: 1;
    position: relative;
  }

  .ig-img__post {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.3s ease;
    background-size: cover;
    background-position: center;
  }

  .ig-img-wrapper:hover {
    .ig-img__post {
      transform: scale(1.1);
    }
  }

  &__box {
    height: 0;
    padding-bottom: 100%;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    >div {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  &.sonic-instafeed {
    border-bottom: 1px solid #EAEAEA;
  }
}

.embedsocial-hashtag {
  margin: 0 -6px;
}

.product-othershop {
  padding: 90px 0;

  @media only screen and (max-width: 1499.9px) {
    padding: 80px 0;
  }

  @media only screen and (max-width: 1199.9px) {
    padding: 70px 0;
  }

  @media only screen and (max-width: 991.9px) {
    padding: 60px 0;
  }

  @media only screen and (max-width: 767.9px) {
    padding: 50px 0 40px;
  }

  @media only screen and (max-width: 575.9px) {
    padding: 40px 0 30px;
    border-top: 1px solid #eaeaea;
    overflow: hidden;
  }

  &__slider {
    max-width: 1210px;
    width: 100%;
    margin: 0 auto;

    a {
      &:hover {
        text-decoration: none;
      }
    }

    @media only screen and (max-width: 1024.9px) {
      max-width: 800px;
    }

    .slick-list {
      @media only screen and (max-width: 1499.9px) {
        margin: 0 -13px;
      }

      @media only screen and (max-width: 1199.9px) {
        margin: 0 -10px;
      }

      @media only screen and (max-width: 991.9px) {
        margin: 0 -8px;
      }

      @media only screen and (max-width: 767.9px) {
        margin: 0 -6px;
      }

      @media only screen and (max-width: 575.9px) {
        margin: 0 -8px;
        overflow: visible;
      }

      .slick-track {
        margin: 0 auto;
      }
    }
  }

  @media only screen and (min-width: 1400px) {
    .container {
      max-width: 1335px;
    }
  }

  h2 {
    text-align: center;
    font-size: 60px;
    line-height: 1.167;
    color: $textColor;
    font-weight: 300;
    margin: 0;

    @media only screen and (max-width: 1199.9px) {
      font-size: 52px;
    }

    @media only screen and (max-width: 991.9px) {
      font-size: 44px;
    }

    @media only screen and (max-width: 767.9px) {
      font-size: 38px;
    }

    @media only screen and (max-width: 575.9px) {
      font-size: 32px;
      text-align: left;
      font-weight: normal;
    }
  }

  img {
    width: 100%;
  }

  &__box {
    border-radius: 5px;
    overflow: hidden;
    margin: 0 16px;
    margin-top: 40px;
    cursor: pointer;

    @media only screen and (max-width: 1499.9px) {
      margin: 0 13px;
      margin-top: 37px;
    }

    @media only screen and (max-width: 1199.9px) {
      margin: 0 10px;
      margin-top: 34px;
    }

    @media only screen and (max-width: 991.9px) {
      margin: 0 8px;
      margin-top: 31px;
    }

    @media only screen and (max-width: 767.9px) {
      margin: 0 6px;
      margin-top: 28px;
    }

    @media only screen and (max-width: 575.9px) {
      margin: 0 8px;
      margin-top: 25px;
    }
  }

  h3 {
    text-align: center;
    font-size: 24px;
    line-height: 1.33;
    color: $textColor;
    margin: 20px auto 0;
    font-weight: normal;
    max-width: 215px;
    width: 100%;

    @media only screen and (max-width: 1199.9px) {
      font-size: 22px;
      max-width: 195px;
    }

    @media only screen and (max-width: 991.9px) {
      font-size: 20px;
      max-width: 160px;
    }

    @media only screen and (max-width: 767.9px) {
      font-size: 18px;
      max-width: 140px;
    }

    @media only screen and (max-width: 575.9px) {
      font-size: 16px;
    }
  }
}

.star-rating-global {
  height: 20px;
  position: relative;
  width: 100px;
  top: -1px;
  margin-right: 16px;
}

.star-rating-global::before {
  background-image: url('/img/rating-inactive.svg');
  background-repeat: repeat-x;
  background-size: auto 100%;
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.star-rating-global>span {
  display: block;
  text-indent: -10000px;
  width: 1000%;
  height: 100% !important;
  position: absolute;
  overflow: hidden;
}

.star-rating-global>span::before {
  background-image: url('/img/rating-active.svg');
  background-repeat: repeat-x;
  background-size: auto 100%;
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  text-indent: 10000px;
  top: 0;
}

.orientation-msg {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(40, 40, 41, 0.65);
  display: none;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 1000;

  @media only screen and (max-width: 1023.9px) and (min-width: 567.9px) and (max-height: 450px) and (orientation: landscape) {
    display: flex;
  }

  div {
    width: 296px;
    padding: 32px 24px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background: #ffffff;

    h2 {
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      font-family: 'Sofia Pro';
      letter-spacing: 0px;
      color: #393838;
      margin: 0 0 8px 0;
    }

    p {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      font-family: 'Sofia Pro';
      letter-spacing: 0px;
      color: #393838;
      margin: 0;
    }
  }
}

@media only screen and (max-width: 1023.9px) and (min-width: 567.9px) and (max-height: 450px) and (orientation: landscape) {
  body {
    overflow: hidden;
  }
}

.ruk_rating_snippet {
  font-family: 'Sofia Pro' !important;
  margin-left: 2px;

  i {
    font-size: 18px;
  }

  .ruk-rating-snippet-count {
    font-family: 'Sofia Pro' !important;
    font-size: 14px;
    line-height: 22px;
    color: #999999;
    margin-left: 10px;
  }
}

.cssVar-badgeElement__text__ {
  display: none;
}

.js-modal-container-dialog {
  .R-BadgeElement {
    .ricon-badge--checkmark {
      margin-top: -4px;
    }
  }

  .R-TextBody {
    .R-BadgeElement {
      display: none;
    }
  }
}

#braze-content-card-wrapper {
  .ab-feed {
    width: 100%;
    background: rgb(245, 248, 253);
    border: none;
    box-shadow: none;
    border-radius: 15px;

    .ab-close-button {
      color: #2baae2;
    }

    .ab-feed-body {
      border: none;

      .ab-card {
        border: none;
        box-shadow: none;
      }

      .ab-feed,
      .ab-card,
      .ab-title {
        font-family: "Sofia Pro" !important;
      }

      .ab-title {
        font-weight: 500;
      }
    }

    .ab-feed-buttons-wrapper {
      background: none;
      box-shadow: none;
    }
  }
}

.star-only-snippet {
  .ruk-rating-snippet-count {
    display: none;
  }
}

.ElementsWidget-prefix .ElementsWidget {
  margin-bottom: 0 !important;
}

.PrimerCheckout .PrimerCheckout__formField:not(:first-of-type) {
  margin-top: 16px !important;
}

iframe.gm-added.gm-lazy:not([class*='embedsocial-']) {
  position: absolute;
  bottom: 0;
  z-index: -1;
}