@use '@carbon/grid';
@import "../globals/colors.scss";

@include grid.flex-grid();

.scroll-up header.sticky.product-page-header {
    top: -40px;
}

.scroll-up header.sticky.product-page-header.disable-notify-bar {
    top: 0;
}

.scroll-down header.sticky.product-page-header {
    top: -152px;

    @media only screen and (max-width: 1055.9px) {
        top: -40px
    }
}

.scroll-down header.sticky.product-page-header.disable-notify-bar {
    top: -112px;

    @media only screen and (max-width: 1055.9px) {
        top: -48px
    }
}

header {
    background: rgba(250, 250, 250, 0.75);
    position: fixed;
    z-index: 102;
    width: 100%;
    left: 0;
    top: 0;
    transition: top 0.3s ease;

    &.sticky:not(.disable-notify-bar) {
        top: -40px;
    }

    .top-notify-bar {
        height: 40px;
        background: #D4E3F9;
        padding: 8px 0;
        text-align: center;
        width: 100%;
        // transform: translateX(-50%);
        text-align: center;
        white-space: nowrap;

        >div {
            overflow-y: hidden;
        }

        &.seasonal-bar {
            background: #E22C2C;
        }

        span {
            display: inline-block;
        }

        &:not(.no-overflow) {
            span {
                margin: 0 0 0 15px !important;
            }

            p {
                margin: 0 0 0 20px !important;
            }
        }

        &.no-overflow {
            span:not(:first-of-type) {
                display: none;
            }
        }

        p {
            font-family: 'Sofia Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            color: #005AE2;
        }

        @media only screen and (max-width: 671.9px) {}
    }

    .main-bar {
        // padding-bottom: 16px;
        background: rgba(250, 250, 250, 0.75);
        border-bottom: 1px solid #E6E6E6;
        min-height: 72px;

        @media only screen and (max-width: 1055.9px) {
            height: 48px;
            min-height: 48px;
            padding-bottom: 0;

            &.active {
                position: relative;
                z-index: 102;
            }
        }

        .container {
            @media only screen and (max-width: 1055.9px) {
                padding: 0 16px;
            }
        }

    }

    .logo-bar {
        min-height: 56px;

        @media only screen and (max-width: 1055.9px) {
            height: 48px;
            min-height: 48px;
        }

        .logo-bar-wrapper {
            padding: 8px 0 0 0;
            position: relative;
            display: flex;
            justify-content: flex-end;

            @media only screen and (max-width: 1055.9px) {
                padding: 16px 0 8px 0;
                justify-content: space-between;
            }

            .ham-menu {
                display: none;
                height: 24px;
                width: 24px;
                padding: 5px 2px;

                @media only screen and (max-width: 1055.9px) {
                    display: block;
                }

                .menu-button {
                    display: inline-block;
                    position: relative;
                    cursor: pointer;
                    z-index: 101;

                    @media only screen and (max-width: 1055.9px) {
                        top: -2px
                    }

                    .line {
                        transition: all 0.3s ease-in-out;
                        height: 2px;
                        background: $Jaguar;
                        display: block;
                        margin-bottom: 4px;
                        border-radius: 2px;
                        width: 20px;
                    }

                    &.active {
                        position: fixed;
                        top: 22px;
                        left: 16px;

                        .line {
                            height: 2px;

                            &:nth-child(1) {
                                transform: translateY(4px) rotate(135deg);
                            }

                            &:nth-child(2) {
                                width: 0;
                            }

                            &:nth-child(3) {
                                transform: translateY(-8px) rotate(-135deg);
                            }
                        }
                    }
                }
            }

            &.active {
                .ham-menu {
                    .menu-button {
                        position: fixed;
                        z-index: 11;
                        left: 32px;
                        top: 25px;

                        @media only screen and (max-width: 991.9px) {
                            left: 30px;
                        }

                        @media only screen and (max-width: 767.9px) {
                            left: 28px;
                        }

                        @media only screen and (max-width: 575.9px) {
                            left: 26px;
                        }

                        .line {
                            height: 2px;

                            &:nth-child(1) {
                                transform: translateY(2px) rotate(135deg);
                            }

                            &:nth-child(2) {
                                width: 0;
                            }

                            &:nth-child(3) {
                                transform: translateY(-14px) rotate(-135deg);
                            }
                        }
                    }
                }
            }

            .logo-wrapper {
                position: absolute;
                top: 24px;
                width: 100%;
                text-align: center;

                @media only screen and (max-width: 1055.9px) {
                    line-height: 0;
                    top: 20px;
                }

                img {
                    height: 24px;

                    @media only screen and (max-width: 1055.9px) {
                        height: 16px;
                    }
                }
            }

            .side-wrapper {
                position: relative;
                z-index: 11;
                padding-right: 32px;
                @media only screen and (max-width: 1499.9px) {
                    padding-right: 0px;
                }

                &.non-cp-wrap {
                    @media only screen and (max-width: 1055.9px) {
                        display: none;
                    }
                }

                .sidebar-dropdown {
                    margin-right: 8px;

                    a {
                        padding: 0;
                        position: static;
                        height: auto;
                        margin-left: 0 !important;
                        min-width: 0;

                        &.selected-cat {
                            color: $zenyum_blue;
                        }
                    }

                    &:hover {
                        .dropdown-menu {
                            display: block;
                            top: 36px;
                        }
                    }
                        @media only screen and (max-width: 1055.9px) {
                            display: none !important; 
                        }
                }

                a {
                    height: 40px;
                    min-width: 40px;
                    padding: 8px;
                    display: inline-block;
                    font-size: 14px;
                    line-height: 24px;
                    text-align: center;
                    border-radius: 40px;
                    position: relative;
                    top: 8px;
                    color: #6E6E73;

                    @media only screen and (max-width: 1055.9px) {
                        display: none;
                        height: 24px;
                        min-width: 24px;
                        padding: 2px;
                        border-radius: 24px;
                        top: -4px;
                    }

                    img {
                        width: 20px;
                        height: 20px;
                    }

                    &:hover {
                        text-decoration: none;
                        color: $Jaguar;
                        background: $light_grey;

                        @media only screen and (max-width: 1055.9px) {
                            background-color: transparent;
                        }
                    }

                    &:last-of-type {
                        margin-left: 8px;

                        @media only screen and (max-width: 1055.9px) {
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            margin: 0;
                            margin-left: 0;
                            padding: 0px 3px;
                            min-width: 24px;

                            img {
                                width: 18px;
                            }
                        }

                        img {
                            width: 18px;
                        }
                    }

                    &.logged-in {
                        padding-left: 16px;

                        p {
                            display: inline-block;
                            margin: 0;
                            padding: 0;
                            margin-right: 8px;
                            margin-top: 2px;
                            line-height: 16px;
                        }

                        &.active {
                            text-decoration: none;
                            color: $Jaguar;
                            background: $light_grey;
                        }
                    }
                }

                .dropdown-menu {
                    width: 213px;
                    padding: 16px 0px 16px 0px;
                    border: 1px solid #E6E6E6;
                    border-radius: 16px;
                    position: absolute;

                    &.account-menu {
                        @media only screen and (min-width: 1056px) {
                            left: auto;
                            right: 40px;
                        }

                    }

                    @media only screen and (max-width: 1055.9px) {
                        position: fixed;
                        width: 100%;
                        height: 100vh;
                        top: 0;
                        left: 0;
                        border: none;
                        border-radius: 0;
                        padding: 56px 16px 0 16px;

                    }

                    li {
                        a {
                            padding: 12px 16px;
                            height: 48px;
                            border-radius: 0;
                            margin-left: 0;
                            width: 100%;
                            text-align: left;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 24px;
                            color: $Jaguar;
                            cursor: pointer;

                            @media only screen and (max-width: 1055.9px) {
                                display: block;
                                text-align: left;
                                width: 100%;
                                padding: 16px 0;
                                font-size: 16px;
                                line-height: 16px;
                                border-bottom: 1px solid #CCCCCC;
                                position: relative;
                            }

                        }

                        &:last-of-type {
                            &>a {
                                @media only screen and (max-width: 1055.9px) {
                                    border-bottom: none;
                                }
                            }
                        }
                    }

                    &.active {
                        display: block;
                    }
                }

                .account-icon {
                    @media only screen and (max-width: 1055.9px) {}

                }

                .account-bar {
                    display: none;

                    @media only screen and (max-width: 1055.9px) {
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        padding: 16px 16px 0 16px;
                        display: flex;
                        justify-content: space-between;

                        .logged-in {
                            display: block;
                            padding-right: 2px;

                            p {
                                display: inline-block;
                                margin-right: 8px;
                            }
                        }
                    }
                }

            }

            .cart-count {
                position: absolute;
                left: 11px;
                top: 14px;
                font-size: 12px;
                line-height: 19px;
                display: block;
                width: 18px;
                height: 18px;
                text-align: center;

                @media only screen and (max-width: 1055.9px) {
                    left: 3px;
                    top: 6px;
                }

            }
        }
    }

    nav {
        position: relative;

        &.non-cp-nav {
            @media only screen and (min-width: 1056px) {
                display: none;
            }
        }

        @media only screen and (max-width: 1055.9px) {
            display: none;

            &.active {
                background: #fff;
                display: block;
                position: fixed;
                height: 100vh;
                width: 100%;
                top: 0;
                left: 0;
                z-index: 10;
            }
        }

        .main-nav {
            list-style-type: none;
            margin: 0;
            padding: 0;
            text-align: center;

            .plus {
                position: absolute;
                right: 24px;
                top: 24px;
                width: 12px;
                height: 12px;
                transition: all 0.5s;

                @media only screen and (max-width: 575.9px) {
                    right: 16px;
                    top: 22px;
                }

                &:before,
                &:after {
                    position: absolute;
                    left: 15px;
                    content: " ";
                    height: 12px;
                    width: 1px;
                    background-color: $Jaguar;
                }

                &:before {
                    transform: rotate(90deg);
                    transition: all 0.5s;
                }

                &:after {
                    transform: rotate(0deg);
                    transition: all 0.5s;
                }

                @media only screen and (min-width: 1056px) {
                    display: none;
                }
            }

            @media only screen and (max-width: 1055.9px) {
                padding: 0 16px;
                margin-top: 56px;
                overflow: auto;
                max-height: calc(100vh - 48px);
            }

            &>li {
                display: inline-block;

                &.nav-blue {
                    color: $zenyum_blue;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                }

                &.mob-link {
                    display: none;

                    @media only screen and (max-width: 1055.9px) {
                        display: block;
                    }
                }

                @media only screen and (max-width: 1055.9px) {
                    display: block;
                }

                &:last-of-type {
                    &>a {
                        @media only screen and (max-width: 1055.9px) {
                            border-bottom: none;
                        }
                    }
                }

                &>a {
                    display: inline-block;
                    padding: 16px;
                    font-size: 16px;
                    margin-bottom: -1px;
                    user-select: none;

                    .shopify-links{
                        text-decoration: none; 
                    }
                    sup{
                        color: #2caae2;
                        font-size: 10px;
                        line-height: 16px;
                    }

                    @media only screen and (max-width: 1055.9px) {
                        display: block;
                        text-align: left;
                        width: 100%;
                        padding: 16px 0;
                        line-height: 24px;
                        border-bottom: 1px solid #CCCCCC;
                        position: relative;
                        margin-bottom: 0;
                    }

                    span {
                        cursor: pointer;
                    }

                    &:hover {
                        text-decoration: none;

                        span {
                            color: $zenyum_blue;

                            @media only screen and (max-width: 1055.9px) {
                                color: $Jaguar;
                            }
                        }

                        .sub-nav {
                            display: block !important;

                            @media only screen and (max-width: 1055.9px) {
                                display: none !important;
                            }
                        }

                    }

                    &.selected-cat {

                        span {
                            color: $zenyum_blue;
                        }
                    }

                    &.active-cat {
                        span {
                            @media only screen and (max-width: 1055.9px) {
                                font-weight: 700;
                                font-family: 'Sofia Pro Semi', sans-serif;
                            }
                        }

                        .plus {
                            &:before {
                                transform: rotate(270deg);
                            }

                            &:after {
                                transform: rotate(90deg);
                            }
                        }

                    }

                    .sub-nav {
                        display: none;
                        color: $Jaguar;
                        position: absolute;
                        min-height: 400px;
                        background: #ffffff;
                        width: 100%;
                        left: 0;
                        top: 56px;
                        z-index: 11;
                        padding: 32px 0 0 0;

                        @media only screen and (max-width: 1055.9px) {
                            &.active-menu {
                                display: block !important;
                            }

                            position: static;
                            min-height: 0;
                            padding: 0;
                        }

                        h4 {
                            font-size: 20px;
                            font-weight: 400;
                            margin: 0;

                            @media only screen and (max-width: 1055.9px) {
                                display: none;
                            }
                        }

                        ul {
                            margin: 0;
                            padding: 0;
                            list-style-type: none;
                            margin-top: 16px;

                            @media only screen and (max-width: 1055.9px) {
                                margin-left: 16px;
                            }

                            li {
                                display: inline-block;
                                margin-right: 16px;
                                vertical-align: top;

                                &.mobile-link {
                                    display: none;
                                }

                                @media only screen and (max-width: 1055.9px) {
                                    margin-right: 0;
                                    display: block;

                                    &.mobile-link {
                                        display: block;
                                    }

                                    &.desktop-link {
                                        display: none;
                                    }
                                }

                                &:last-of-type {
                                    margin-right: 0;
                                }

                                a {
                                    display: block;
                                    width: 176px;

                                    @media only screen and (max-width: 1055.9px) {
                                        width: 100%;
                                        position: relative;
                                        color: #6E6E73;
                                    }

                                    &:hover {
                                        text-decoration: none;
                                        color: $zenyum_blue;
                                    }

                                    img {
                                        width: 100%;
                                        background: #f3f3f3;
                                        border-radius: 16px;

                                        @media only screen and (max-width: 1055.9px) {
                                            display: none;
                                        }

                                        &.forward {
                                            @media only screen and (max-width: 1055.9px) {
                                                display: inline;
                                            }

                                            display: none;
                                            position: absolute;
                                            height: 12px;
                                            width: 7px;
                                            right: 8px;
                                            top: 4px;
                                        }
                                    }

                                    p {
                                        margin: 0;
                                        padding: 0;
                                        margin-top: 8px;
                                        font-size: 14px;
                                        line-height: 24px;

                                        @media only screen and (max-width: 1055.9px) {
                                            margin-top: 16px;
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                        }

                        .nav-box-bottom {
                            margin-top: 32px;
                            text-align: center;
                            margin-bottom: 16px;

                            @media only screen and (max-width: 1055.9px) {
                                margin-top: 16px;
                                text-align: left;
                                padding-left: 16px;
                            }

                            a {
                                display: inline-block;
                                text-decoration: underline;
                                padding: 8px 24px;
                                height: 40px;
                                border-radius: 48px;
                                font-size: 16px;

                                @media only screen and (max-width: 1055.9px) {
                                    padding: 0;
                                    display: inline;
                                    color: #6E6E73;
                                }

                                &:hover {
                                    background-color: $light_grey;
                                    color: $Jaguar;
                                }
                            }
                        }

                    }
                }
            }
        }

        .about-discover {
            display: none !important;
            margin-top: 0;

            @media only screen and (max-width: 1055.9px) {
                display: block !important;
            }

        }

        .navbar-gap {
            display: none !important;
            height: 32px;
            background: #FAFAFA;

            @media only screen and (max-width: 1055.9px) {
                display: block !important;
            }
        }
    }

    &.detail-page-header {
        .main-bar {
            border-bottom: 1px solid #CCCCCC;

            @media only screen and (max-width: 1055.9px) {
                border-color: #E6E6E6;
            }
        }

        .product-secondary-navbar {
            background: rgba(250, 250, 250, 0.75);
            border-bottom: 1px solid #EBEBEB;
            min-height: 64px;

            h4 {
                color: $Jaguar;
                font-size: 16px;
            }

            a.web-btn {
                padding: 8px 45px;
                min-width: 0;
                line-height: 1.715;
                border: none;
                font-size: 14px;

                @media only screen and (max-width: 1055.9px) {
                    padding: 8px 8px;
                }
            }
        }

        @media only screen and (max-width: 1055.9px) {
            &.sticky {
                transform: none !important;
                top: -110px;
            }

            ~div footer {
                padding-bottom: 70px;
            }

            .product-secondary-navbar {
                position: fixed !important;
                top: auto;
                bottom: 0;
                padding: 24px 0;
                height: auto;
                background: $white;
                box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.05);
                margin: 0 !important;

                .web-btn {
                    min-width: 138px !important;
                    line-height: 1.6;

                    @media only screen and (max-width: 575.9px){
                        min-width: 96px !important;
                    }

                    &.uvbox-morebtn {
                        min-width: 190px !important;
                    }
                }

                h4 {
                    color: $Jaguar;
                    font-size: 16px;
                    padding-top: 4px;

                    @media only screen and (max-width: 575.9px){
                        font-size: 14px;
                    }

                    span {
                        font-weight: bold;
                    }

                    s {
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    .price-nav-block {
        min-width: 33.333333%;
        margin-left: auto;
        padding: 0 15px;

        @media only screen and (max-width: 1199.9px) {
            min-width: 41.666667%;
        }

        @media only screen and (max-width: 991.9px) {
            min-width: 50%;
        }

        @media only screen and (max-width: 768.9px) {
            min-width: 0;
            width: 100%;
        }

        h4 {
            margin-right: 32px;

            @media only screen and (max-width: 1055.9px) {
                margin-right: 8px;
            }
        }
    }

    .mobile-account-menu {
        display: none;
        width: 100%;
        z-index: 102;
        position: fixed;
        bottom: 0;
        height: 64px;
        background: #FAFAFA;
        padding: 0 16px;
        align-items: center;

        img {
            height: 16px;
            width: 16px;
        }

        span {
            display: inline-block;
            margin-left: 8px;
            font-size: 12px;
            line-height: 16px;
            color: $Jaguar;
            vertical-align: middle;
        }
    }

    &.menu-open {
        .mobile-account-menu {
            display: flex;

            @media only screen and (min-width: 1056px) {
                display: none;
            }

            &.menu-active {
                display: none;
            }
        }

        .cart-link {
            @media only screen and (max-width: 1055.9px) {
                display: block;
                position: fixed !important;
                z-index: 12;
                top: 16px !important;
                right: 16px !important;
            }

        }

        .logo-wrapper {
            @media only screen and (max-width: 1055.9px) {
                position: fixed !important;
                z-index: 12;
                left: 0;
            }
        }

    }

    .scrollabe-mobile {
        @media only screen and (max-width: 1055.9px) {
            padding-bottom: 64px;
            max-height: 100vh;
            overflow: auto;
        }
    }

    .top-fixed-section {
        display: none;

        @media only screen and (max-width: 1055.9px) {
            display: block;
            position: fixed;
            background: $white;
            height: 48px;
            z-index: 15;
            top: 0;
            left: 0;
            width: 100%;

        }
    }

}

section.pagetop-headerspace {
    padding-top: 152px;
    margin-top: 40px;

    @media only screen and (max-width: 1055.9px) {
        padding-top: 88px;
    }
}


.disable-notify-bar~section .pagetop-headerspace {
    padding-top: 112px;

    @media only screen and (max-width: 1055.9px) {
        padding-top: 48px;
    }
}