@import "globals/media-query.scss";
@import "globals/colors.scss";

.alert-wrapper {
    background: $Jaguar;
    padding: 10px 24px 8px;
    color: $white;
    display: flex;
    cursor: pointer;
    span{
        display: block;
        font: normal normal normal 12px/14px Sofia Pro;
        border: 1px solid $white;
        text-align: center;
        padding: 3px 7px;
        border-radius: 5px;
        margin: 1px 8px 0 0;
        
    }
    p{
        margin: 0;
        font: normal normal normal 16px/22px Sofia Pro;
    }
   
}

.toast-wrapper {
    background: $Jaguar;
    padding: 10px 24px 8px;
    color: $white;
    display: flex;
    position: fixed;
    opacity: .9;
    left: 0;
    width: 100%;
    p{
        margin: 0 auto;
        text-align: center;
        font: normal normal normal 16px/22px Sofia Pro;
    }
   
}

@include mobile {
    .alert-wrapper {
        font: normal normal normal 14px/18px Sofia Pro;
        letter-spacing: 0.08px;
        h4 {
            font: normal normal bold 16px/22px Sofia Pro;
        }
    }
    .toast-wrapper {
        &.cart-page{
            bottom: 75px;
            top: auto !important;
        }
        &.fresh-page{
            top: 70px !important;
        }
       
    }
    .detail-page-header{
        .toast-wrapper{
            bottom: 74px;
            top: auto !important;
        }
    }

}
