$Hawkes_Blue: #dbdee7;
$Jaguar: #282829;
$Jaguar_70: #282829b3;
$zenyum_blue: #2caae2;
$zenyum_azure_blue: #008CFF;
$white: #ffffff;
$danger: #e22c2c;
$dark_grey: #999999;
$buttonDisabled: #ACB7BF;
$light_grey: #F3F3F3;
$secondary_text_grey: #6E6E73;
$deep_blue: #EFF4FC;
$magenta: #F71E62;
$disabled_background: #E0E0E1;
$disabled_text: #86868B;
$border_grey: #E6E6E6;
$moon-yellow: #F0B90F;
$ivory: #FFFFF2;
$moon_yellow: #F0B90F;
$amaranth:#DC334B;
$whisper: #EBEBEB;
$nero: #1C1C1C;
$zambezi: #5B5B5B;
$black: #000000;
$dark_nero: #1A1A1A;
$snow: #FAFAFA;
$ghost_white: #F5F5F7;
$nero: #1F1F1F;
$gainsboro: #dddddd;
$grey_chateau_40: #8E99A840;
$border_grey:#E6E6E6;
$navy_blue: #005AE2;
$navy_blue_2: #0063CC;
$dodger_blue: #0090FF;
$pattens_blue: #CCE8FF;
$pattens_blue_2: #D5EAFF;
$whisper: #EEEEEE;
$matterhorn: #555555;
$very_light_grey: #CCCCCC;
$grey: #888888;
$gainsboro_light: #DBDBDB;
$nero_light: #161616;
$dim_gray: #707070;
$quartz: #D2D2D7;
$whale_tail: #86868b;
$black_1a: #0000001a;
$pale_cornflower_blue_4d: #b2dcff4d;
$persian_green: #09A490;
$pumpkin: #FF7E27;
$mosque: #00695B;
$solitude: #E5E8F3;
$tory_blue: #40528C;
$light_purple: #40528C;
$blue_gray: #E5E8F3;
$eclipse: #393838; 
$pattens_blue: #CEE4FC;
$eden: #136753;
$amaranth_1: #DC3345;
$humming_bird: #CDEDE5;
$maya_blue: #66BAFF;
$white75: #FFFFFFBF;
$light_azure: #E6F4FF;
$royal_blue: #005AE2;
$light-gray-blue: #B1B1B4;
$slate_gray: #53576d;